import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { useWeb3React } from '@web3-react/core'
import { Toast, toastTypes } from '@doublemoondev/doublemoon-uikit'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { vaultsConfig } from 'config/constants'
import { Team } from 'config/constants/types'
import Nfts from 'config/constants/nfts'
import { BIG_ZERO } from 'utils/bigNumber'
import { getWeb3NoAccount } from 'utils/web3'
import { getAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  setBlock,
  fetchVaultsPublicDataAsync,
  fetchVaultUserDataAsync
} from './actions'
import { State, Farm, FarmsState, Pool, ProfileState, TeamsState, AchievementState, PriceState, VaultsState, Vault } from './types'
import { fetchProfile } from './profile'
import { fetchTeam, fetchTeams } from './teams'
import { fetchAchievements } from './achievements'
import { fetchPrices } from './prices'
import { fetchWalletNfts } from './collectibles'

// Vaults
export const useFetchVaultsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const pids = vaultsConfig.map((vaultToFetch) => vaultToFetch.pid)

    dispatch(fetchVaultsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchVaultUserDataAsync({ account, pids }))
    }
  }, [dispatch, slowRefresh, account])
}

export const useVaults = (): VaultsState => {
  const vaults = useSelector((state: State) => state.vaults)
  return vaults
}

export const useVaultFromPid = (pid): Vault => {
  const vaults = useSelector((state: State) => state.vaults.data.find((f) => f.pid === pid))
  return vaults
}

export const useVaultUser = (pid) => {
  const vault = useVaultFromPid(pid)

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : BIG_ZERO,
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : BIG_ZERO,
  }
}

// Farms

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { currentBlock } = useBlock()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchPoolsPublicDataAsync(currentBlock))
  }, [dispatch, slowRefresh, currentBlock])

  useEffect(() => {
    const web3 = getWeb3NoAccount()
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch])
}

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromSymbol(symbol)
  const tokenPriceInUsd = useGetApiPrice(getAddress(farm.token.address))

  return farm.lpTotalSupply && farm.lpTotalInQuoteToken
    ? new BigNumber(getBalanceNumber(farm.lpTotalSupply)).div(farm.lpTotalInQuoteToken).times(tokenPriceInUsd).times(2)
    : new BigNumber(0)
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Toasts
export const useToast = () => {
  const dispatch = useAppDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Profile

export const useFetchProfile = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchProfile(account))
  }, [account, dispatch])
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading }
}

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id])
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTeam(id))
  }, [id, dispatch])

  return team
}

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTeams())
  }, [dispatch])

  return { teams: data, isInitialized, isLoading }
}

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account))
    }
  }, [account, dispatch])
}

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data)
  return achievements
}

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPrices())
  }, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}

export const useGetApiPrice = (address: string) => {
  const prices = useGetApiPrices()

  if (!prices) {
    return null
  }

  return prices[address.toLowerCase()]
}

export const usePriceDOPDolly = (): BigNumber => {
  const dopDollyVault = useVaultFromPid(3)
  const dopDollyPrice = dopDollyVault.token.busdPrice ? new BigNumber(dopDollyVault.token.busdPrice) : BIG_ZERO
  return dopDollyPrice
}

export const usePriceTWINBusd = (): BigNumber => {
  const twinBnbVault = useVaultFromPid(8)
  const twinBusdPrice = twinBnbVault.token.busdPrice ? new BigNumber(twinBnbVault.token.busdPrice) : BIG_ZERO
  return twinBusdPrice
}

export const usePriceTWXBusd = (): BigNumber => {
  const twxBnbVault = useVaultFromPid(22)
  const twxBusdPrice = twxBnbVault.token.busdPrice ? new BigNumber(twxBnbVault.token.busdPrice) : BIG_ZERO
  return twxBusdPrice
}

export const usePriceDOPXBusd = (): BigNumber => {
  const twinBnbVault = useVaultFromPid(13)
  const twinBusdPrice = twinBnbVault.token.busdPrice ? new BigNumber(twinBnbVault.token.busdPrice) : BIG_ZERO
  return twinBusdPrice
}

export const usePriceFINIXBusd = (): BigNumber => {
  const twinBnbVault = useVaultFromPid(19)
  const twinBusdPrice = twinBnbVault.token.busdPrice ? new BigNumber(twinBnbVault.token.busdPrice) : BIG_ZERO
  return twinBusdPrice
}

export const usePriceDMCBusd = (): BigNumber => {
  const dmcBnbFarm = useFarmFromPid(2)
  const bnbBusdFarm = useFarmFromPid(3)
  const bnbBusdPrice = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO
  // const bnbBusdPrice = new BigNumber(350)
  // console.log('bnbBusdPrice: ', bnbBusdPrice.toString())
  const dmcBusdPrice = dmcBnbFarm.tokenPriceVsQuote ? bnbBusdPrice.times(dmcBnbFarm.tokenPriceVsQuote) : BIG_ZERO
  // console.log('dmcBusdPrice: ', dmcBusdPrice.toString())
  return dmcBusdPrice
}

export const usePriceDBMBusd = (): BigNumber => {
  const dbmBnbFarm = useFarmFromPid(1)
  const bnbBusdFarm = useFarmFromPid(3)
  const bnbBusdPrice = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO
  // const bnbBusdPrice = new BigNumber(350)
  const dbmBusdPrice = dbmBnbFarm.tokenPriceVsQuote ? bnbBusdPrice.times(dbmBnbFarm.tokenPriceVsQuote) : BIG_ZERO
  return dbmBusdPrice
}

export const usePriceBNBBusd = (): BigNumber => {
  const ZERO = new BigNumber(0)
  const bnbBusdFarm = useFarmFromPid(3)
  const bnbBusdPrice = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO
  // const bnbBusdPrice = new BigNumber(350)
  return bnbBusdPrice
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}

// Collectibles
export const useGetCollectibles = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { isInitialized, isLoading, data } = useSelector((state: State) => state.collectibles)
  const identifiers = Object.keys(data)

  useEffect(() => {
    // Fetch nfts only if we have not done so already
    if (!isInitialized) {
      dispatch(fetchWalletNfts(account))
    }
  }, [isInitialized, account, dispatch])

  return {
    isInitialized,
    isLoading,
    tokenIds: data,
    nftsInWallet: Nfts.filter((nft) => identifiers.includes(nft.identifier)),
  }
}
