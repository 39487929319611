import tokens from './tokens'
import { VaultConfig, VaultCategory, VaultPlatform } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 9,
    symbol: 'DMC',
    strategyAddresses: {
      97: '0x6b3DaAd13DB8a19Bc30556c6794d7aFC478f2D77',
      56: '0x3C28D849F87FB5e55FCa6c6852b4835BB8783f52',
    },
    masterChefAddresses: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    lpAddresses: {
      97: '0x9b5cAb9ca8b8451b94a2545A00c3e9559B228058',
      56: '0xA7bD090bC58e663388Be63d5E8a7b683f5A3d4FC',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.DOUBLEMOON,
    routerUrl: 'https://exchange.doublemoon.finance',
    token: tokens.dmc,
    quoteToken: tokens.wbnb,
    earnToken: tokens.dmc,
    withdrawFee: 0.1,
    performanceFee: 0,
    compoundFrequency: 8,
    icon: 'dmc.svg',
    sortOrder: 1,
    legacy: true,
  },
  {
    pid: 0,
    symbol: 'DMC (Legacy)',
    strategyAddresses: {
      97: '0xaB27D08766154759e3DF7F671a265B16A845A047',
      56: '0xaA966146EAa9919e1A35fB654BC2278Ebd4E9C64',
    },
    masterChefAddresses: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    lpAddresses: {
      97: '0x9b5cAb9ca8b8451b94a2545A00c3e9559B228058',
      56: '0xA7bD090bC58e663388Be63d5E8a7b683f5A3d4FC',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.DOUBLEMOON,
    routerUrl: 'https://exchange.doublemoon.finance',
    token: tokens.dmc,
    quoteToken: tokens.wbnb,
    earnToken: tokens.dmc,
    withdrawFee: 0,
    performanceFee: 0,
    compoundFrequency: 8,
    icon: 'dmc.svg',
    sortOrder: 1,
    legacy: true,
  },
  {
    pid: 1,
    symbol: 'DMC-BNB',
    strategyAddresses: {
      97: '0xa3fB7C1d19217d884A11BE54749D353fEcB7225D',
      56: '0x6F97d166c79ACbF11e6678B29e0023CD942c92a0',
    },
    masterChefAddresses: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    lpAddresses: {
      97: '0x3ce4e2124dcb08f7da60e80094895cb03f85a763',
      56: '0x9657B13b78C82017e372D78cA063ADD1Ce52cdB8',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DOUBLEMOON,
    routerUrl: 'https://exchange.doublemoon.finance',
    token: tokens.dmc,
    quoteToken: tokens.wbnb,
    earnToken: tokens.dmc,
    withdrawFee: 0.1,
    performanceFee: 0,
    compoundFrequency: 8,
    icon: 'dmc-bnb.svg',
    sortOrder: 2,
    legacy: true,
  },
  {
    pid: 12,
    symbol: 'DOPX',
    strategyAddresses: {
      97: '',
      56: '0x98cd694e6A6bb894ab51E1694CE19B7001b1ce7D',
    },
    masterChefAddresses: {
      97: '',
      56: '0x45069E9632d1b29d16C5E06dc9Ed8F33B6aBe6DB',
    },
    lpAddresses: {
      97: '',
      56: '0x802A183ac9F6b082716DFeE55432ed0C04ACB49a',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.DOPPLE,
    routerUrl: 'https://twindex.com',
    token: tokens.dopx,
    quoteToken: tokens.wbnb,
    earnToken: tokens.dopx,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 1,
    icon: 'dopx.svg',
    sortOrder: 3,
  },
  {
    pid: 13,
    symbol: 'DOPX-BNB',
    strategyAddresses: {
      97: '',
      56: '0x5cb2d41fBf0614A7ef7C7C2173E72369dcC10fBf',
    },
    masterChefAddresses: {
      97: '',
      56: '0x45069E9632d1b29d16C5E06dc9Ed8F33B6aBe6DB',
    },
    lpAddresses: {
      97: '',
      56: '0x9661A41eeA814A9b13f2C1d844106cEf6c3a4A43',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DOPPLE,
    routerUrl: 'https://twindex.com',
    token: tokens.dopx,
    quoteToken: tokens.wbnb,
    earnToken: tokens.dopx,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 1,
    icon: 'dopx-bnb.svg',
    sortOrder: 4,
  },
  // {
  //   pid: 14,
  //   symbol: 'DOPX-USDC',
  //   strategyAddresses: {
  //     97: '',
  //     56: '0xfBD3848fbb219b209ecf0Bc5a05cb27cD090Af1b',
  //   },
  //   masterChefAddresses: {
  //     97: '',
  //     56: '0x45069E9632d1b29d16C5E06dc9Ed8F33B6aBe6DB',
  //   },
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7b559c6724de298a7915169ebdb9b31f4e057bfc',
  //   },
  //   category: VaultCategory.PAIR,
  //   platform: VaultPlatform.DOPPLE,
  //   routerUrl: 'https://twindex.com',
  //   token: tokens.dopx,
  //   quoteToken: tokens.usdc,
  //   earnToken: tokens.dopx,
  //   withdrawFee: 0.1,
  //   performanceFee: 4,
  //   compoundFrequency: 2,
  //   icon: 'dopx-usdc.svg',
  //   sortOrder: 4,
  // },
  {
    pid: 3,
    symbol: 'DOP-DOLLY (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0x5Ad2f28A055087439e6341B3CA57b0be6443e24D',
    },
    masterChefAddresses: {
      97: '',
      56: '0xDa0a175960007b0919DBF11a38e6EC52896bddbE',
    },
    lpAddresses: {
      97: '',
      56: '0xaF8f9922Ea7b00A8F7F9Cb50729B65f0D99446D6',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DOPPLE,
    routerUrl: 'https://twindex.com',
    token: tokens.dop,
    quoteToken: tokens.dolly,
    earnToken: tokens.dop,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 8,
    icon: 'dop-dolly.svg',
    sortOrder: 6,
    legacy: true,
  },
  {
    pid: 10,
    symbol: 'DOP (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0xaeE08a0f4a89A4dC841CE46784DB18a51A8b2A2E',
    },
    masterChefAddresses: {
      97: '',
      56: '0xDa0a175960007b0919DBF11a38e6EC52896bddbE',
    },
    lpAddresses: {
      97: '',
      56: '0x844FA82f1E54824655470970F7004Dd90546bB28',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.DOPPLE,
    routerUrl: 'https://twindex.com',
    token: tokens.dop,
    quoteToken: tokens.dolly,
    earnToken: tokens.dop,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'dop.svg',
    sortOrder: 5,
    legacy: true,
  },
  {
    pid: 20,
    symbol: 'TWX',
    strategyAddresses: {
      97: '',
      56: '0xD9a6279C2A28987AAe2CD048fB89A49ea7f183D7',
    },
    masterChefAddresses: {
      97: '',
      56: '0xDF44098408B4Cf5F123e78e0dce802f72ba2E557',
    },
    lpAddresses: {
      97: '',
      56: '0x41171D5770C4c68686d1aF042Ada88a45B02f82b',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twx,
    quoteToken: tokens.wbnb,
    earnToken: tokens.twx,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 1,
    icon: 'twx.svg',
    sortOrder: 6,
  },
  {
    pid: 21,
    symbol: 'TWX-KUSD',
    strategyAddresses: {
      97: '',
      56: '0xfF29D3cb29FE0e85D67Cf674488C5a7d2d664CF4',
    },
    masterChefAddresses: {
      97: '',
      56: '0xDF44098408B4Cf5F123e78e0dce802f72ba2E557',
    },
    lpAddresses: {
      97: '',
      56: '0x08422f6Cc26cCDa692a36a73A520Da6b0E6d3DE3',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twx,
    quoteToken: tokens.kusd,
    earnToken: tokens.twx,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 2,
    icon: 'twx-kusd.svg',
    sortOrder: 6,
  },
  {
    pid: 22,
    symbol: 'TWX-BNB',
    strategyAddresses: {
      97: '',
      56: '0x0EAA08DA5FD46A8d632B2EC687302399BAcD32c8',
    },
    masterChefAddresses: {
      97: '',
      56: '0xDF44098408B4Cf5F123e78e0dce802f72ba2E557',
    },
    lpAddresses: {
      97: '',
      56: '0xFD555bD87b98160649DFaA42eaE46B022d71c71A',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twx,
    quoteToken: tokens.wbnb,
    earnToken: tokens.twx,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 2,
    icon: 'twx-bnb.svg',
    sortOrder: 6,
  },
  {
    pid: 11,
    symbol: 'TWIN (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0x996d0F62B226a190A4f0B083Dd04fcF3d950bBFb',
    },
    masterChefAddresses: {
      97: '',
      56: '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed',
    },
    lpAddresses: {
      97: '',
      56: '0x3806aae953a3a873D02595f76C7698a57d4C7A57',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twin,
    quoteToken: tokens.wbnb,
    earnToken: tokens.twin,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'twin.svg',
    sortOrder: 6,
    legacy: true,
  },
  {
    pid: 5,
    symbol: 'DOP-TWIN (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0xE0F1951c4c5d7234621347db97D829D834b38f6B',
    },
    masterChefAddresses: {
      97: '',
      56: '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed',
    },
    lpAddresses: {
      97: '',
      56: '0x65A95C2BC5c12E8e30e24D322ff386249c29a072',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twin,
    quoteToken: tokens.dop,
    earnToken: tokens.twin,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 2,
    icon: 'dop-twin.svg',
    sortOrder: 6,
    legacy: true,
  },
  {
    pid: 6,
    symbol: 'TWIN-DOLLY (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0x605423e8Aa73A2C28e2c5d7de2d07f981150A9d9',
    },
    masterChefAddresses: {
      97: '',
      56: '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed',
    },
    lpAddresses: {
      97: '',
      56: '0x593747Cd0023669c7A67511406266559B166Ca5d',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twin,
    quoteToken: tokens.dolly,
    earnToken: tokens.twin,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 8,
    icon: 'twin-dolly.svg',
    sortOrder: 6,
    legacy: true,
  },
  {
    pid: 8,
    symbol: 'TWIN-BNB (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0x7Faa50cc9D632589426Ecf698ED4034a9aBaF28f',
    },
    masterChefAddresses: {
      97: '',
      56: '0xe6bE78800f25fFaE4D1db7CA6d3485629bD200Ed',
    },
    lpAddresses: {
      97: '',
      56: '0xC3BFdd2cd502e719132Bf7aA5954C74e9C7209F5',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.TWINDEX,
    routerUrl: 'https://twindex.com',
    token: tokens.twin,
    quoteToken: tokens.wbnb,
    earnToken: tokens.twin,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 8,
    icon: 'twin-bnb.svg',
    sortOrder: 6,
    legacy: true,
  },
  {
    pid: 15,
    symbol: 'WAD-BUSD (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0xdC7C6971DdEC6e70c64479695a1ec0ead9C98299',
    },
    masterChefAddresses: {
      97: '',
      56: '0xde866dd77b6df6772e320dc92bff0eddc626c674',
    },
    lpAddresses: {
      97: '',
      56: '0xc95B1750043FCE5dfCc8539835Ea3830Ec002A89',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.WARDEN,
    routerUrl: 'https://liquidity.wardenswap.finance/#/',
    token: tokens.wad,
    quoteToken: tokens.busd,
    earnToken: tokens.wad,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'wad-busd.svg',
    sortOrder: 7,
    legacy: true,
  },
  {
    pid: 16,
    symbol: 'WAD-USDT (Legacy)',
    strategyAddresses: {
      97: '',
      56: '0x344b2C74240947e8c5609A3E77865ee5B60675c2',
    },
    masterChefAddresses: {
      97: '',
      56: '0xde866dd77b6df6772e320dc92bff0eddc626c674',
    },
    lpAddresses: {
      97: '',
      56: '0x7D6195b212deE1BA7DE8404cfd319446cf17D71B',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.WARDEN,
    routerUrl: 'https://pancakeswap.finance/liquidity',
    token: tokens.wad,
    quoteToken: tokens.usdt,
    earnToken: tokens.wad,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'wad-usdt.svg',
    sortOrder: 7,
    legacy: true,
  },
  {
    pid: 17,
    symbol: 'FINIX',
    strategyAddresses: {
      97: '',
      56: '0xF999278217bC2Cc32330303982e435cDA4B43Bdc',
    },
    masterChefAddresses: {
      97: '',
      56: '0x6b51E8FDc32Ead0B837deb334fcB79E24F3b105A',
    },
    lpAddresses: {
      97: '',
      56: '0x0F02b1F5AF54E04Fb6dd6550f009aC2429C4e30D',
    },
    category: VaultCategory.SINGLE,
    platform: VaultPlatform.DEFINIX,
    routerUrl: 'https://exchange.definix.com',
    token: tokens.finix,
    quoteToken: tokens.wbnb,
    earnToken: tokens.finix,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 1,
    icon: 'finix.svg',
    sortOrder: 8,
  },
  {
    pid: 18,
    symbol: 'FINIX-BNB',
    strategyAddresses: {
      97: '',
      56: '0xf7D40D5716f8F887b2D0200330F4D2BcE4cc45de',
    },
    masterChefAddresses: {
      97: '',
      56: '0x6b51E8FDc32Ead0B837deb334fcB79E24F3b105A',
    },
    lpAddresses: {
      97: '',
      56: '0xB2A2048de5afb312Fcf076aBf505952c12916f0d',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DEFINIX,
    routerUrl: 'https://exchange.definix.com',
    token: tokens.finix,
    quoteToken: tokens.wbnb,
    earnToken: tokens.finix,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'finix-bnb.svg',
    sortOrder: 8,
  },
  {
    pid: 19,
    symbol: 'FINIX-BUSD',
    strategyAddresses: {
      97: '',
      56: '0xA0cFD4946b269a4b4161424874Dd4fAad3E69349',
    },
    masterChefAddresses: {
      97: '',
      56: '0x6b51E8FDc32Ead0B837deb334fcB79E24F3b105A',
    },
    lpAddresses: {
      97: '',
      56: '0x9d19afdc03A710cf54f6B3d3764C7d3B26AC892F',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DEFINIX,
    routerUrl: 'https://exchange.definix.com',
    token: tokens.finix,
    quoteToken: tokens.busd,
    earnToken: tokens.finix,
    withdrawFee: 0.1,
    performanceFee: 4,
    compoundFrequency: 4,
    icon: 'finix-busd.svg',
    sortOrder: 8,
  },
]

export default vaults
