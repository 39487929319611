import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/moonMaster.json'
import masterChefABI from 'config/abi/masterChef.json'
import erc20 from 'config/abi/erc20.json'
import strategyMasterChefABI from 'config/abi/strategyMasterChef.json'
import { VaultCategory } from 'config/constants/types'
import { getAddress, getMoonMasterAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { Vault, SerializedBigNumber } from '../types'

type PublicVaultData = {
  tokenAmountMc: SerializedBigNumber
  quoteTokenAmountMc: SerializedBigNumber
  lpTokenBalanceMc: SerializedBigNumber
  tokenAmountTotal: SerializedBigNumber
  quoteTokenAmountTotal: SerializedBigNumber
  vaultSharesTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  lpMcTotalInQuoteToken: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  masterChefPid: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
}

const fetchVault = async (vault: Vault): Promise<PublicVaultData> => {
  const { pid, strategyAddresses, lpAddresses, masterChefAddresses, token, quoteToken } = vault
  const strategyAddress = getAddress(strategyAddresses)
  const lpAddress = getAddress(lpAddresses)
  const masterChefAddress = getAddress(masterChefAddresses)
  const calls = [
    // Balance of token in the LP contract
    {
      address: getAddress(token.address),
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: getAddress(quoteToken.address),
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens in the master chef contract
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [masterChefAddress],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: getAddress(token.address),
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: getAddress(quoteToken.address),
      name: 'decimals',
    },
  ]

  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMc, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
    await multicall(erc20, calls)
  // console.log('lpTokenBalanceMc: ', lpTokenBalanceMc.toString())

  const callStrategyMasterChefs = [
    // Balance of LP tokens in strategy master chef contract
    {
      address: strategyAddress,
      name: 'vaultSharesTotal',
    },
    // PID of master chef
    {
      address: strategyAddress,
      name: 'pid',
    },
  ]
  const [vaultSharesTotal, masterChefPid] = (pid || pid === 0) ? await multicall(strategyMasterChefABI, callStrategyMasterChefs) : [0, 0]
  // console.log(pid, 'vaultSharesTotal=====', vaultSharesTotal.toString(), masterChefPid.toString())

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenSourceMCRatio = new BigNumber(lpTokenBalanceMc).div(new BigNumber(lpTotalSupply))
  const lpTokenRatio = new BigNumber(vaultSharesTotal).div(new BigNumber(lpTotalSupply))

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

  // Amount of token in the LP that are staked in the MC (i.e amount of token * lp ratio)
  const tokenAmountMc = tokenAmountTotal.times(lpTokenRatio)
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)
  const quoteTokenAmountSourceMc = quoteTokenAmountTotal.times(lpTokenSourceMCRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))
  const lpMcTotalInQuoteToken = quoteTokenAmountSourceMc.times(new BigNumber(2))

  // Only make masterchef calls if vault has pid
  const [info, totalAllocPoint] = pid || pid === 0 ? await multicall(masterChefABI, [
          {
            address: masterChefAddress,
            name: 'poolInfo',
            params: [masterChefPid.toString()],
          },
          {
            address: masterChefAddress,
            name: 'totalAllocPoint',
          },
        ]) : [null, null]
  const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

  return {
    tokenAmountMc: tokenAmountMc.toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    lpTokenBalanceMc: lpTokenBalanceMc.toString(),
    tokenAmountTotal: tokenAmountTotal.toJSON(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
    vaultSharesTotal: new BigNumber(vaultSharesTotal).toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    lpMcTotalInQuoteToken: lpMcTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    masterChefPid: masterChefPid.toString(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
  }
}

export default fetchVault
