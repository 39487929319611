import tokens from './tokens'
import { VaultConfig, VaultCategory, VaultPlatform } from './types'

const priceHelperLps: VaultConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absense of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    symbol: 'BUSD-BNB',
    strategyAddresses: {
      97: '',
      56: '',
    },
    masterChefAddresses: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    category: VaultCategory.PAIR,
    platform: VaultPlatform.DOUBLEMOON,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    earnToken: tokens.busd,
    withdrawFee: 0,
    performanceFee: 0,
    compoundFrequency: 0,
    icon: '',
    sortOrder: 0,
  }
]

export default priceHelperLps
