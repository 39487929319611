import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    title: 'DMC Solo',
    multiply: 28,
    icon: 'dmc.svg',
    stakingToken: tokens.dmc,
    earningToken: tokens.dmc,
    contractAddress: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4.999558811',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 6,
    title: 'DBM Solo',
    multiply: 10,
    icon: 'dbm.svg',
    stakingToken: tokens.dbm,
    earningToken: tokens.dmc,
    contractAddress: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.764757787',
    sortOrder: 2,
    isFinished: false,
  },
  {
    sousId: 8,
    title: 'WBNB Solo',
    multiply: 2,
    icon: 'bnb.svg',
    stakingToken: tokens.wbnb,
    earningToken: tokens.dmc,
    contractAddress: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.3529515574',
    sortOrder: 3,
    isFinished: false,
  },
  {
    sousId: 7,
    title: 'BUSD Solo',
    multiply: 2,
    icon: 'busd.svg',
    stakingToken: tokens.busd,
    earningToken: tokens.dmc,
    contractAddress: {
      97: '0x8f9C2fD28aBD139f1368B8aE789B0b9CeE7454a1',
      56: '0xbe739A112eF6278cEb374Bcad977252Bc3918cA9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.3529515574',
    sortOrder: 4,
    isFinished: false,
  },
  {
    sousId: 1,
    title: 'DMC (earn DBM)',
    multiply: 0,
    icon: 'dmc.svg',
    stakingToken: tokens.dmc,
    earningToken: tokens.dbm,
    contractAddress: {
      97: '0xEE631394E997a9407B094Ad5a265b467435edb3B',
      56: '0xe935d95a39F429B621164225A8F3Bbf2c0E5a8F9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 5,
    tokenPerBlock: '3000000',
  },
]

export default pools
