import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'DoubleMoon',
  description:
    'Doublemoon Finance the next generation decentralized lucky platform. We provided lucky draw and betting system for Defi firm.',
  image: 'https://app.doublemoon.finance/images/512x512_App_Icon.png',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/competition': {
    title: 'DoubleMoon Easter Battle',
    description: 'Register now for the DoubleMoon Easter battle!',
    image: 'https://pancakeswap.finance/images/easter-battle.png',
  },
}
