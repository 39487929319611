import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@doublemoondev/doublemoon-uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-light: #FD9316;
    --primary: #FD9316;
    --primary-dark: #FF5B2C;
    --accent: #7AEFD5;
    --accent-dark: #33dcb7;
    --secondary-accent: #D91FEA;
    --secondary-light: #1A4089;
    --secondary: #1A4089;
    --secondary-dark: #1A4089;
    --tertiary-light: #1A4089;
    --tertiary: #1A4089;
    --tertiary-dark: #1A4089;
    --background-300: rgba(255, 255, 255, 0.08);
    --background-400: #172746;
    --background: #081832;
    --background-dark: #0B1830;
    --surface: #FFF;
    --error: #FFF;
    --warning: #FFC900;
    --on-primary: #FFF;
    --on-accent: #4C249F;
    --on-secondary: #FFF;
    --on-background: #FFFFFF;
    --on-background-fade: rgba(255, 255, 255, 0.25);
    --on-surface: #FFF;
    --on-error: #FFF;
    
    --navbar: #1A4089;
    --on-navbar: #FFFFFF;

    --navbar-height: 60px;
  }
  * {
    font-family: 'Kanit', sans-serif;
  }
  html, body {
    background-color: var(--background);
  }
`

export default GlobalStyle
