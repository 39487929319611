import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    isFarm: false,
    lpSymbol: 'DMC',
    lpAddresses: {
      97: '0x9b5cAb9ca8b8451b94a2545A00c3e9559B228058',
      56: '0xA7bD090bC58e663388Be63d5E8a7b683f5A3d4FC',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    isFarm: true,
    lpSymbol: 'DBM-BNB LP',
    lpAddresses: {
      97: '0x084fee16321a9514454d13cFED4fAA027631D031',
      56: '0x5423f8f560b2e9dd1974247ebfc73d12f795800b',
    },
    token: tokens.dbm,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    isFarm: true,
    lpSymbol: 'DMC-BNB LP',
    lpAddresses: {
      97: '0x3ce4e2124dcb08f7da60e80094895cb03f85a763',
      56: '0x9657B13b78C82017e372D78cA063ADD1Ce52cdB8',
    },
    token: tokens.dmc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 3,
    isFarm: true,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 4,
    isFarm: true,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },
  {
    pid: 5,
    isFarm: true,
    lpSymbol: 'DBM-SCZ LP',
    lpAddresses: {
      97: '',
      56: '0xF31455AE22DfE3637b87D62371F6fc945F9ea301',
    },
    token: tokens.scz,
    quoteToken: tokens.dbm,
  },
  {
    pid: 6,
    isFarm: false,
    lpSymbol: 'DBM',
    lpAddresses: {
      56: '0x0314e5a39806C30D67B869EE1bCDABee7e08dE74',
      97: '0xAfF3543F3811c7B667Bcb6bf321f05c3F6Bef96A',
    },
    token: tokens.dbm,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 7,
    isFarm: false,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 8,
    isFarm: false,
    lpSymbol: 'WBNB',
    lpAddresses: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    token: tokens.wbnb,
    quoteToken: tokens.wbnb,
  },
]

export default farms
